<template>
  <div class="tabs">
      <template  v-for="(item, index) in list" >

        <el-button v-if='!item.isImport' :key="index" @click="handleEdit(item, index)" :loading='item.isLoading' size="small"  :type="item.type">{{ item.name }}</el-button>

        <div class="button" style="margin: 0 10px;" :key="index" v-else>
          <el-button :loading='item.isLoading' size="small"  :type="item.type">{{ item.name }}</el-button>
          <input @change="getFile" accept='.xls, .xlsx' title='' class="file" type="file" name="" id="">
        </div>
      </template>
      <!-- <div style="margin-right: 10px;" >
      </div> -->
      <!-- isImport -->
  </div>
</template>

<script>
export default {
  name: 'tabs',
  props: {
    list: {
      type: Array,
      default() {
        return []
      },
    },
    tabChange: Function,
    fileChange: Function,
  },
  data () {
    return {
      array: [],
    }
  },
  methods: {
    handleEdit: function(item, index) {
      this.tabChange && this.tabChange({ item, index })
    },
    getFile: function(event) {
      // console.log(event, '这是文件meiyou chuf ')
      const { target } = event
      const { files } = target
      this.fileChange && this.fileChange(files[0])
    },
  },
  watch: {
    list(newV) {
      // console.log(newV, '这是：：：：')
      newV && (this.array = newV)
    },
  },
}
</script>

<style lang="stylus" scoped>
.tabs {
  // width 100%;
  height 32px;
  margin-bottom 16px;
  display flex;
  align-items center;
  .button{
    background-color red;
    position relative
    .file {
      position absolute;
      left 0;
      width 80px;
      height 32px;
      cursor pointer;
      z-index 99999
      outline:none;
      opacity 0
      z-index 1
    }
  }
}
</style>
