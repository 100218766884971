<template>
  <div v-if="list.length" class="all-search">
    <template v-for="(item, index) in list">
      <div class="item-search" :key="index" v-if="item.type === 'input'">
        <span class="key blod">{{ item.name }}：</span>
        <el-input
          class="value"
          @input="changeInput"
          v-model="$data[item.key]"
          placeholder="请输入内容"
        ></el-input>
      </div>

      <div class="item-search" :key="index" v-if="item.type === 'picker'">
        <span class="key blod">{{ item.name }}：</span>
        <el-select class="value" v-model="$data[item.key]" placeholder="请选择">
          <el-option
            v-for="sub in item.options"
            :key="sub[item.value]"
            :label="sub[item.label]"
            :value="sub[item.value]"
          >
          </el-option>
        </el-select>
      </div>

      <div class="item-search" :key="index" v-if="item.type === 'date'">
        <span class="key blod">{{ item.name }}：</span>
        <el-date-picker
          class="date-value"
          v-model="$data[item.key]"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
        >
        </el-date-picker>
      </div>
    </template>

    <el-button @click="onSearch" class="submit" type="primary">搜索</el-button>
  </div>
</template>

<script>
import { dateMap } from '@/utils/util'
export default {
  name: 'search',
  props: {
    list: {
      type: Array,
      default() {
        return []
      },
    },
    searchChange: Function,
  },
  data() {
    return {
      // 下面是对应input框的值
      projectName: '', // 工程名称
      projectType: '', // 工程类型
      projectUnit: '', // 所属单位
      createTime: '', // 创建时间
      fenceName: '', // 围栏名称
      fenceProject: '', // 所属工程
      deepName: '', // 深基坑名称
      conProgress: '', // 施工进度
      towerName: '', // 杆塔名称
      towerType: '', // 杆塔类型
      towerPower: '', // 杆塔电压
      name: '', // 姓名
      idState: '', // 证件状态
      // bUnit: '', // 承包单位
      // dUnit: '', // 承办单位
      workType: '', // 工作证类型
      username: '', // 账户
      phoneNumber: '', // 手机号
      userPosition: '', // 职务
      unitName: '', // 单位名称
      type: '', // 分类
      title: '', // 标题
      quesType: '', // 题型
      phone: '', // 手机号
      id: '', // 身份证
      faceType: '', // 人脸设备
      // ------
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    }
  },
  methods: {
    onSearch: function () {
      const current = {}
      this.list.forEach(item => {
        if (item.key === 'createTime') {
          // console.log(this.createTime, ':::::')
          if (this.createTime && !this.createTime[0]) this.createTime = []
          this.createTime = this.createTime ? dateMap(this.createTime) : ['', '']
          current[item.key] = this.createTime
        } else {
          current[item.key] = this[item.key]
        }
      })
      this.searchChange && this.searchChange(current)
    },
    changeInput: function (e) {
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="stylus" scoped>
.all-search {
  width: 100%;
  // margin-bottom: 24px;
  display: flex;
  align-items: center;
  // justify-content space-around;
  flex-wrap: wrap;

  .item-search {
    width 430px;
    display flex;
    align-items center;
    justify-content space-between;
    margin-right: 41px;
    margin-bottom 20px;
    .key {
      font-size: $Fontsize;
      color: #515A6E;
    }

    .value {
      width: 332px;
      // height: 32px;
    }
    .date-value {
      width 332px;
    }
  }

  .submit {
    width: 70px;
    height: 38px;
    margin-top: 10px;
    padding: 0;
    margin-top -18px;
  }
}
</style>
