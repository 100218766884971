<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :show-close="false"
    width="45%"
    :title="`${isEdit ? '编辑' : '添加'}分类`"
    :visible.sync="isShow"
  >
    <div class="all-input">
       <div style="width: 80% !important" class="item-input">
        <div class="label"><span class="red">*</span>分类名称：</div>
        <el-input v-model="formData.name" class="big" placeholder="请输入名称"></el-input>
      </div>
      <div style="width: 80% !important" class="item-input">
        <div class="label"><span class="red">*</span>上级分类：</div>
         <el-cascader
           class="big"
           v-model="formData.pid"
          :options="optionsCas"
          :props="propsCas"
          clearable></el-cascader>
      </div>
      <div style="width: 80% !important" class="item-input">
        <div class="label"><span class="red">*</span>排序：</div>
        <el-input-number
          class="big"
          v-model="formData.sort"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getISelect, addIType, updIFile } from '@/api/monitor'
export default {
  name: 'addTVideo',
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isShow: true, // 是否打开弹窗
      options: [],
      optionsCas: [],
      propsCas: {
        checkStrictly: true,
        value: 'id',
        label: 'name',
        children: 'childs',
      },
      fileList: [],
      formData: {
        name: '',
        pid: '',
        sort: '',
      },
      toastData: {
        name: '请选择分类名称',
        pid: '请选择上级分类',
      },
      isEdit: false,
    }
  },
  created() {
    this._loadSelect()
    if (this.data.id) {
      this.isEdit = true
      this.formData = this.data
      this.formData.pid = String(this.data.pid)
    }
  },
  methods: {
    _loadSelect: function () {
      getISelect().then(res => {
        const { result } = res
        this.optionsCas = result
        this.deepData(this.optionsCas)
      })
    },
    deepData: function(list) {
      for (let i = 0; i < list.length; i++) {
        list[i].id = String(list[i].id)
        if (list[i].childs && list[i].childs.length) this.deepData(list[i].childs)
      }
    },

    close: function (isLoad) {
      this.$emit('close')
    },

    submit: function () {
      for (const key in this.toastData) {
        if (!this.formData[key]) {
          this.$toast(this.toastData[key])
          return false
        }
      }

      if (typeof this.formData.pid === 'object') {
        this.formData.pid = this.formData.pid[this.formData.pid.length - 1]
      }
      if (!this.isEdit) this.save()
      else this.edit()
    },

    save: function () {
      addIType(this.formData).then((res) => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.$emit('close', 'success')
        }
      })
    },

    edit: function () {
      updIFile(this.formData).then((res) => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.$emit('close', 'success')
        }
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.all-input {
  justify-content: space-between;

  .file {
    align-items: flex-start;
  }
}
</style>
