import { PublicAxios } from '@/utils/publicAxios'
const $ = new PublicAxios()

// --------------------------------------------
// 监控中心API接口
// --------------------------------------------

/**
 * 获取视频列表
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getMonitorList = () => {
  return $.get({
    url: 'admin/bind/monitoringList',
    isLoad: true,
  })
}

/**
 * 获取单兵位置
 */
export const getDeviceMarker = () => {
  return $.get({
    url: 'admin/device/signleMark',
  })
}

/**
 * 搜索单兵轨迹
 * @param {Object} data 详看接口文档
 */
export const getDeviceLine = data => {
  return $.post({
    url: 'admin/device/signleTrack',
    data,
  })
}

/**
 * 获取视频文件
 * @param {String} id 文件id
 */
export const getVFiles = id => {
  return $.get({
    url: 'admin/digital/videoList',
    data: { id },
    isLoad: true,
  })
}

/**
 * 获取视频列表分类
 * @param {Object} data 详看接口文档
 */
export const getVSelect = data => {
  return $.get({
    url: 'admin/bind/videoTypeList',
    data,
  })
}

/**
 * 添加视频
 * @param {Object} data 详看接口文档
 */
export const addVideo = data => {
  return $.post({
    url: 'admin/bind/videoAdd',
    data,
  })
}

/**
 * 获取视频详情
 * @param {String} id 视频id
 */
export const getVideoDetail = id => {
  return $.get({
    url: 'admin/bind/videoEdit',
    data: { id },
  })
}

/**
 * 更新视频
 * @param {Obejct} data 详看接口文档
 */
export const updVideo = data => {
  return $.post({
    url: 'admin/bind/videoEdit',
    data,
  })
}

/**
 * 删除视频
 * @param {String} id 视频id
 */
export const delVideo = id => {
  return $.get({
    url: 'admin/bind/videoDel',
    data: { id },
  })
}

/**
 * 删除文件夹
 * @param {String} id 文件夹id
 */
export const delVFile = id => {
  return $.get({
    url: 'admin/bind/videoFolder',
    data: { id },
  })
}

/**
 * 修改文件夹
 * @param {Object} data 详看接口文档
 */
export const updVFile = data => {
  return $.post({
    url: 'admin/bind/videoTypeEdit',
    data,
  })
}

/**
 * 获取照片文件
 * @param {String} id 文件id
 */
export const getIFiles = id => {
  return $.get({
    url: 'admin/digital/filesList',
    data: { id },
    isLoad: true,
  })
}

/**
 * 获取照片分类
 */
export const getISelect = () => {
  return $.get({
    url: 'admin/digital/digitalTypeList',
  })
}

/**
 * 添加照片
 * @param {Object} data 详看接口文档
 */
export const addImg = data => {
  return $.post({
    url: 'admin/digital/digitalAdd',
    data,
  })
}

/**
 * 获取照片详情
 * @param {Object} id 详看接口文档
 */
export const getImgDetail = id => {
  return $.get({
    url: 'admin/digital/digitalDetail',
    data: { id },
  })
}

/**
 * 更新照片
 * @param {Object} data 详看接口文档
 */
export const updImg = data => {
  return $.post({
    url: 'admin/digital/digitalEdit',
    data,
  })
}

/**
 * 删除照片
 * @param {String} ids 照片id
 */
export const delImg = ids => {
  return $.get({
    url: 'admin/digital/digitalDel',
    data: { ids },
  })
}

/**
 * 删除文件
 * @param {Object} ids 文件id
 */
export const delIFile = ids => {
  return $.get({
    url: 'admin/digital/digitalTypeDel',
    data: { ids },
  })
}

/**
 * 更新文件夹名称
 * @param {Object} data 详看接口文档
 */
export const updIFile = data => {
  return $.post({
    url: 'admin/digital/digitalTypeEdit',
    data,
  })
}

/**
 * 获取视频分类
 * @param {Object} data 详看接口文档
 */
export const getVType = data => {
  return $.get({
    url: 'admin/bind/videoTypeList',
    data,
  })
}

/**
 * 添加视频分类
 * @param {Object} data 详看接口文档
 */
export const addVType = data => {
  return $.post({
    url: 'admin/bind/videoTypeAdd',
    data,
  })
}

/**
 * 获取分类详情
 * @param {String} id 分类id
 */
export const getVTypeDetail = id => {
  return $.get({
    url: 'admin/bind/videoTypeEdit',
    data: { id },
  })
}

/**
 * 编辑视频分类
 * @param {Object} data 详看接口文档
 */
export const updVType = data => {
  return $.post({
    url: 'admin/bind/videoTypeEdit',
    data,
  })
}

/**
 * 获取照片分类
 * @param {Object} data 详看接口文档
 */
export const getIType = data => {
  return $.get({
    url: 'admin/digital/digitalTypeList',
    data,
  })
}

/**
 * 获取照片详情
 * @param {String} id 详看接口文档
 */
export const getITypeDetail = id => {
  return $.get({
    url: 'admin/digital/digitalTypeDetail',
    data: { id },
  })
}

/**
 * 添加照片分类
 * @param {Object} data 详看接口文档
 */
export const addIType = data => {
  return $.post({
    url: 'admin/digital/digitalTypeAdd',
    data,
  })
}
