<template>
  <el-table
    :data="data"
    style="width: 100%; margin-bottom: 20px"
    row-key="id"
    border
    :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
  >
  <template v-for="(item, index) in list">
      <el-table-column
        v-if="!item.type"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        :width="item.width || '120'"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column
        v-else-if="item.type === 'but' || item.type === 'other'"
        :key="index"
        :label="item.label"
      >
        <template slot-scope="scope">
          <div class="buttons">
            <el-button
              v-for="(sub, i) in item.type === 'but'
                ? scope.row.buttons
                : scope.row.other"
              :key="`${index}-${i}`"
              size="mini"
              class="item-button"
              :loading="sub.isLoading"
              :type="sub.type"
              @click="handleEdit(scope.row, scope.$index, i)"
              >{{ sub.name }}</el-button
            >
          </div>
        </template>
      </el-table-column>

      <el-table-column
        v-else-if="item.type === 'tag'"
        :key="index"
        :label="item.label"
      >
        <template slot-scope="scope">
          <el-tag :type="scope.row.tags.type" disable-transitions>{{
            scope.row.tags.name
          }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column
        v-else-if="item.type === 'img'"
        :key="index"
        :label="item.label"
      >
        <template slot-scope="scope">
          <div class="imgs">
            <el-avatar
            v-for="(sub, i) in scope.row.imgs"
            :key="`${index}-${i}`"
            shape="square"
            :size="20"
            fit="fill"
            :src="sub.url"
          ></el-avatar>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        v-else-if="item.type === 'switch'"
        :key="index"
        :label="item.label"
      >
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.switch.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :disabled='scope.row.switch.disabled'
            @change="handleSwitch(scope.row)"
            >
          </el-switch>
        </template>
      </el-table-column>
    </template>
  </el-table>
</template>

<script>
export default {
  nickname: 'table',
  props: {
    isShowSele: {
      // 是否显示前面的选择框
      type: Boolean,
      default() {
        return true
      },
    },
    list: {
      // 表头列表
      type: Array,
      default() {
        return []
      },
    },
    data: {
      // 列表数据
      type: Array,
      default() {
        return []
      },
    },
    buttonChange: Function, // 操作方法
    selectChange: Function, // 选中方法
    switchChange: Function, // 切换方法
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
    handleSelectionChange: function (array) {
      const ids = []
      array.forEach((item) => {
        ids.push(item.id)
      })

      this.selectChange && this.selectChange(ids.join(','))
    },
    handleEdit: function (arr, index, i) {
      this.buttonChange && this.buttonChange(arr, arr.buttons[i].func)
    },

    handleSwitch: function(curr) {
      this.switchChange && this.switchChange(curr, curr.switch.func)
    },
  },
}
</script>

<style lang="stylus" scoped>
.buttons {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  button {
    margin: 5px;
  }
}
</style>
